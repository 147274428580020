import imageProject01 from '../styles/images/image-project-01.svg';
import imageProject02 from '../styles/images/image-project-02.svg';
import imageProject03 from '../styles/images/image-project-03.svg';
import imageProject04 from '../styles/images/image-project-04.svg';
import imageProject05 from '../styles/images/image-project-05.svg';
import imageProject06 from '../styles/images/image-project-06.svg';
import imageProject07 from '../styles/images/image-project-07.svg';
import imageProject08 from '../styles/images/image-project-08.svg';
import imageProject09 from '../styles/images/image-project-09.svg';
import imageProject10 from '../styles/images/image-project-10.svg';
import imageProject11 from '../styles/images/image-project-11.svg';
import imageProject12 from '../styles/images/image-project-12.svg';
import imageProject13 from '../styles/images/image-project-13.svg';
import imageProject14 from '../styles/images/image-project-14.svg';
import imageProject15 from '../styles/images/image-project-15.svg';
import imageProject16 from '../styles/images/image-project-16.svg';
import imageProject17 from '../styles/images/image-project-17.svg';
import imageProject18 from '../styles/images/image-project-18.svg';
import imageProject19 from '../styles/images/image-project-19.svg';


const projects = [
	 {
		id: 1,
		name: 'Lessons Learned',
		category: 'frontend',
		date: '20/09/2022',
		duration: 'Menos de 1 dia',
		image: imageProject01,
		soloOrGroup : 'solo',
		deploy: 'https://primeiro-projeto.surge.sh',
		github: 'https://github.com/viniciusjosedev/project-lessons-learned',
		technologies: ['HTML', 'CSS']
	},
	{
		id: 2,
		name: 'Playground Functions',
		category: 'frontend',
		date: '27/09/2022',
		duration: 'Menos de 2 dias',
		image: imageProject02,
		soloOrGroup : 'solo',
		github: 'https://github.com/viniciusjosedev/project-playground-functions',
		technologies: ['JavaScript']
	},
	{
		id: 3,
		name: 'Arte com Pixels',
		category: 'frontend',
		date: '04/10/2022',
		duration: '4 dias',
		image: imageProject03,
		soloOrGroup : 'solo',
		deploy: 'https://segundo-projeto.surge.sh',
		github: 'https://github.com/viniciusjosedev/project-pixels-art',
		technologies: ['JavaScript']
	},
	{
		id: 4,
		name: 'Meme Generator',
		category: 'frontend',
		date: '09/10/2022',
		duration: '1 dia',
		image: imageProject04,
		soloOrGroup : 'solo',
		deploy: 'https://terceiro-projeto.surge.sh',
		github: 'https://github.com/viniciusjosedev/project-meme-generator',
		technologies: ['HTML', 'CSS', 'JavaScript']
	},
	{
		id: 5,
		name: 'Trybewarts',
		category: 'frontend',
		date: '14/10/2022',
		duration: '4 dias',
		image: imageProject05,
		soloOrGroup : 'solo',
		deploy: 'https://quarto-projeto.surge.sh/',
		github: 'https://github.com/viniciusjosedev/project-trybewarts',
		technologies: ['HTML', 'CSS', 'JavaScript']
	},
	{
		id: 6,
		name: 'JavaScript Teste Unitários',
		category: 'frontend',
		date: '22/10/2022',
		duration: '2 dias',
		image: imageProject06,
		soloOrGroup : 'solo',
		github: 'https://github.com/viniciusjosedev/project-js-unit-tests',
		technologies: ['JavaScript', 'Jest']
	},
	{
		id: 7,
		name: 'Zoo Functions',
		category: 'frontend',
		date: '31/10/2022',
		duration: '4 dias',
		image: imageProject07,
		soloOrGroup : 'solo',
		github: 'https://github.com/viniciusjosedev/projeto-zoo-functions',
		technologies: ['JavaScript', 'Jest']
	},
	{
		id: 8,
		name: 'Carrinho de Compras',
		category: 'frontend',
		date: '16/11/2022',
		duration: '3 dias',
		image: imageProject08,
		soloOrGroup : 'solo',
		deploy: 'https://quinto-projeto.surge.sh/',
		github: 'https://github.com/viniciusjosedev/projeto-zoo-functions',
		technologies: ['HTML', 'CSS', 'JavaScript', 'Jest']
	},
	{
		id: 9,
		name: 'Solar System',
		category: 'frontend',
		date: '27/11/2022',
		duration: 'Menos de 1 dia',
		image: imageProject09,
		soloOrGroup : 'solo',
		deploy: 'https://sexto-projeto.surge.sh/',
		github: 'https://github.com/viniciusjosedev/solar-system',
		technologies: ['React', 'HTML', 'CSS', 'JavaScript']
	},
	{
		id: 10,
		name: 'Tryunfo',
		category: 'frontend',
		date: '01/12/2022',
		duration: '3 dias',
		image: imageProject10,
		soloOrGroup : 'solo',
		deploy: 'https://setimo-projeto.surge.sh/',
		github: 'https://github.com/viniciusjosedev/project-tryunfo',
		technologies: ['React', 'HTML', 'CSS', 'JavaScript']
	},
	{
		id: 11,
		name: 'Trybetunes',
		category: 'frontend',
		date: '27/12/2022',
		duration: '5 dias',
		image: imageProject11,
		soloOrGroup : 'solo',
		deploy: 'https://oitavo-projeto.surge.sh/',
		github: 'https://github.com/viniciusjosedev/project-trybetunes',
		technologies: ['React', 'HTML', 'CSS', 'JavaScript']
	},
	{
		id: 12,
		name: 'FrontEnd Online Store',
		category: 'frontend',
		date: '11/01/2023',
		duration: '5 dias',
		image: imageProject12,
		soloOrGroup : 'group',
		deploy: 'https://nono-projeto.surge.sh/',
		github: 'https://github.com/viniciusjosedev/project-frontend-online-store',
		technologies: ['React', 'HTML', 'CSS', 'JavaScript']
	},
	{
		id: 13,
		name: 'Testes em React',
		category: 'frontend',
		date: '20/01/2023',
		duration: 'Menos de 1 dia',
		image: imageProject13,
		soloOrGroup : 'solo',
		deploy: 'https://decimo-projeto.surge.sh/',
		github: 'https://github.com/viniciusjosedev/project-react-testing-library',
		technologies: ['React', 'RTL','HTML', 'CSS', 'JavaScript']
	},
	{
		id: 14,
		name: 'Trybe Wallet',
		category: 'frontend',
		date: '01/02/2023',
		duration: 'Menos de 1 dia',
		image: imageProject14,
		soloOrGroup : 'solo',
		deploy: 'https://decimo-primeiro-projeto.surge.sh/',
		github: 'https://github.com/viniciusjosedev/project-trybe-wallet',
		technologies: ['React', 'RTL', 'HTML', 'CSS', 'JavaScript']
	},
	{
		id: 15,
		name: 'Jogo de Trivia',
		category: 'frontend',
		date: '12/02/2023',
		duration: '10 dias',
		image: imageProject15,
		soloOrGroup : 'group',
		deploy: 'https://grupo-10-trivia.surge.sh/',
		github: 'https://github.com/viniciusjosedev/project-trivia-react-redux',
		technologies: ['React', 'RTL', 'HTML', 'CSS', 'JavaScript']
	},
	{
		id: 16,
		name: 'StarWars Planets Search em Context API e Hooks',
		category: 'frontend',
		date: '28/02/2023',
		duration: '2 dias',
		image: imageProject16,
		soloOrGroup : 'solo',
		deploy: 'https://decimo-terceiro-projeto.surge.sh/',
		github: 'https://github.com/viniciusjosedev/project-trivia-react-redux',
		technologies: ['React', 'RTL', 'HTML', 'CSS', 'JavaScript']
	},
	{
		id: 17,
		name: 'App de Receitas',
		category: 'frontend',
		date: '07/03/2023',
		duration: '7 dias',
		image: imageProject17,
		soloOrGroup : 'group',
		deploy: 'https://app-receitas.surge.sh/',
		github: 'https://github.com/viniciusjosedev/project-recipes-app',
		technologies: ['React', 'RTL', 'HTML', 'CSS', 'JavaScript']
	},
	{
		id: 18,
		name: 'Lista de Tarefas com Docker',
		category: 'backend',
		date: '21/03/2023',
		duration: 'Menos de 1 dia',
		image: imageProject18,
		soloOrGroup : 'solo',
		github: 'https://github.com/viniciusjosedev/project-docker-todo-list',
		technologies: ['Docker', 'Docker-Compose']
	},
	{
		id: 19,
		name: 'All For One',
		category: 'backend',
		date: '27/03/2023',
		duration: 'Menos de 1 dia',
		image: imageProject19,
		soloOrGroup : 'solo',
		github: 'https://github.com/viniciusjosedev/project-mysql-all-for-one',
		technologies: ['SQL', 'MySQL']
	},
]

export default projects;
