export function animationGenerationHome(instance, style) {
	return (
		instance.options({ speed: 20, deleteSpeed: 20, loop: true }).pause(500).move(-197).pause(1500).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).delete(9)
		.type(`<span class="${style.colorBack}">Back-End</span>`).pause(3000).delete(8)
		.type(`<span class="${style.colorBack}">Full-</span><span class="${style.colorFront}">Stack</span>`).pause(3000).delete(10)
		.type(`<span class="${style.colorFront}">Front-End</span>`).pause(3000).pause(3000).move(197)
  )
};

export function animationGenerationProjects(instance, styles) {
	return (
		instance.options({ speed: 30, deleteSpeed: 30, loop: true }).pause(500).move(-19).pause(500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.move(19)

	)
}

export function animationGenerationProjectsFilter(instance, styles) {
	return (
		instance.options({ loop: true, speed: 30, deleteSpeed: 15 }).pause(500).move(-1)
		.pause(1500).delete(15).pause(100).type(`<span class="${ styles.colorPurple }">produção</span>`)
		.pause(1500).delete(8).pause(100).type(`<span class="${ styles.colorBlue }">desenvolvimento</span>`)
		.pause(1500).delete(15).pause(100).type(`<span class="${ styles.colorPurple }">produção</span>`)
		.pause(1500).delete(8).pause(100).type(`<span class="${ styles.colorBlue }">desenvolvimento</span>`)
		.pause(1500).delete(15).pause(100).type(`<span class="${ styles.colorPurple }">produção</span>`)
		.pause(1500).delete(8).pause(100).type(`<span class="${ styles.colorBlue }">desenvolvimento</span>`)
		.pause(1500).delete(15).pause(100).type(`<span class="${ styles.colorPurple }">produção</span>`)
		.pause(1500).delete(8).pause(100).type(`<span class="${ styles.colorBlue }">desenvolvimento</span>`)
		.pause(1500).delete(15).pause(100).type(`<span class="${ styles.colorPurple }">produção</span>`)
		.pause(1500).delete(8).pause(100).type(`<span class="${ styles.colorBlue }">desenvolvimento</span>`)
		.pause(1500).delete(15).pause(100).type(`<span class="${ styles.colorPurple }">produção</span>`)
		.pause(1500).delete(8).pause(100).type(`<span class="${ styles.colorBlue }">desenvolvimento</span>`)
		.pause(1500).delete(15).pause(100).type(`<span class="${ styles.colorPurple }">produção</span>`)
		.pause(1500).delete(8).pause(100).type(`<span class="${ styles.colorBlue }">desenvolvimento</span>`)
		.pause(1500).delete(15).pause(100).type(`<span class="${ styles.colorPurple }">produção</span>`)
		.pause(1500).delete(8).pause(100).type(`<span class="${ styles.colorBlue }">desenvolvimento</span>`)
		.pause(1500).delete(15).pause(100).type(`<span class="${ styles.colorPurple }">produção</span>`)
		.pause(1500).delete(8).pause(100).type(`<span class="${ styles.colorBlue }">desenvolvimento</span>`)
		.pause(1500).delete(15).pause(100).type(`<span class="${ styles.colorPurple }">produção</span>`)
		.pause(1500).delete(8).pause(100).type(`<span class="${ styles.colorBlue }">desenvolvimento</span>`)
		.pause(1500).move(1)
		
	)
}

export function animationGenerationProjectsTechnologies(instance, styles) {
	return (
		instance.options({ loop: true, speed: 30, deleteSpeed: 15 })
		.pause(1500)
		.move(-1)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.delete(9).type(`<span class="${styles.colorPurple}">Back-End</span>`).pause(1500).delete(8)
		.type(`<span class="${styles.colorBlue}">Front-End</span>`).pause(1500)
		.move(1)
	)
};


export function animationGenerationContacts(instance, styles) {
	return (
		instance.options({ loop: true, speed: 30, deleteSpeed: 15 }).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
		.delete(9).type(`<span class=${styles.colorBack}>Email!</span>`).pause(1500)
		.delete(6).type(`<span class=${styles.colorFront}>Linkedin!</span>`).pause(1500)
	)
}; 
